import React from "react";
import bannerrightimg from "../../images/banner-img-right.svg";

const Banner = () => {
  return (
    <section
      id="banner"
      className=" container mx-auto px-4 lg:min-h-[90vh] h-full max-lg:flex-col-reverse lg:justify-between max-lg:gap-8 bg-bgBanner bg-no-repeat bg-right-top max-lg:pt-8 items-center relative flex overflow-hidden max-lg:justify-center py-8"
    >
      <div className="h-100 flex flex-wrap flex-col lg:w-6/12 max-lg:p-8 items-center">
        <h1 className="lg:text-8xl text-6xl text-slate-900 font-medium lg:text-left text-center">
          Hello! I am Altmesh
          <span className="text-red-600">,</span>
        </h1>
        <p className="lg:text-left text-2xl mt-4 text-gray-600">
          a passionate UI/UX designer with a vision to transform ideas into
          visually stunning and user-friendly experiences. I specialize in
          creating captivating digital interfaces that leave a lasting
          impression.
        </p>
      </div>
      <div className="lg:w-5/12 max-lg:px-32 max-md:px-12 min-w-64 min-h-64 w-full flex justify-center lg:hover:translate-x-5 transition ease-in-out">
        <img
          className="max-lg:max-h-full w-full lg:hover:translate-x-5 transition ease-in-out"
          src={bannerrightimg}
          alt=""
        />
      </div>
    </section>
  );
};

export default Banner;
