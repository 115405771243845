import React from "react";
import logo from "../images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container px-4 mx-auto max-lg:justify-center flex items-center py-8 justify-between max-lg:flex-col max-lg:gap-8">
        <div className="branding flex flex-wrap items-center gap-4 w-full lg:w-4/12 lg:justify-start justify-center">
          <img
            className="max-w-[4rem] max-lg:max-w-[3.5rem]"
            src={logo}
            alt=""
          />
          <div className="footer-title flex text-left flex-col">
            <h3 className="text-4xl text-slate-900 max-lg:text-2xl">Altmesh Khan</h3>
            <p className="text-xl text-gray-600">UI UX Designer</p>
          </div>
        </div>
        <div className="contact-info lg:w-8/12 lg:gap-16 gap-4 flex max-lg:flex-col max-lg:items-center lg:justify-end">
          <a
            href="mailto:khanaltmesh@gmail.com"
            className="flex gap-4 ease-in-out text-slate-900 hover:scale-105 lg:pr-8 lg:justify-end"
          >
            <FontAwesomeIcon icon={faEnvelope} size="xl" />
            <p>khanatlmesh@gmail.com</p>
          </a>
          <a
            href="mailto:khanaltmesh@gmail.com"
            className="flex gap-4 text-slate-900 ease-in-out hover:scale-105 lg:pl-8 border-l-2"
          >
            <FontAwesomeIcon icon={faPhone} size="xl" />
            <p >+91-966-976-6616</p>
          </a>
        </div>
      </div>
      <div className="py-4 max-lg:px-[4rem] bg-slate-900">
        <p className="text-white">
          Copyright © 2023 Altmesh Khan. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
