import React from "react";

const About = () => {
  return (
    <section id='about'>
      <div className='container mx-auto px-4 h-[80vh] flex items-center md:px-16'>
        <p className='text-3xl px-8 leading-normal max-md:text-2xl max-md:px-8 Comfortaa font-semibold'>
          Experienced UI/UX Designer obsessed on developing meaningful digital studies. Expertise in
          user studies, prototyping, and visual layout. Committed to crafting intuitive interfaces
          that force person engagement and satisfaction. Let's collaborate to carry your thoughts to
          existence!
        </p>
      </div>
    </section>
  );
};

export default About;
