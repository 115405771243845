import React from "react";
import "./App.css";
import {Footer, Header} from "./components";
import {About, Banner} from "./components/Sections";

function App() {
  return (
    <div className='App'>
      <Header />
      <Banner />
      <About />
      <Footer />
    </div>
  );
}

export default App;
