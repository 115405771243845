import React from "react";
import logo from "../images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faBehance,
  faFigma,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  return (
    <nav>
      <div className="navbar container px-4 mx-auto max-lg:px-4">
        <div className="container justify-between max-lg:flex-col max-lg:gap-8 mx-auto py-8 items-center flex">
          <div className="items-center flex gap-2">
            <img
              className="w-12 lg:hover:scale-110 ease-in-out cursor-pointer lg:hover:rotate-45 transition"
              src={logo}
              alt="Banner"
            />
          </div>
          <div className="header-social-icon gap-8 flex items-center flex-wrap">
            <a
              href="https://www.instagram.com/insta_altmesh/"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition ease-in-out"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="2xl"
                className="hover:text-slate-900 text-slate-600"
              />
            </a>
            <a
              href="https://www.behance.net/Altmesh"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition ease-in-out"
            >
              <FontAwesomeIcon
                icon={faBehance}
                size="2xl"
                className="hover:text-slate-900 text-slate-600"
              />
            </a>
            <a
              href="https://www.figma.com/@altmesh"
              target="_blank"
              rel="noreferrer"
              className="lg:hover:scale-110 transition ease-in-out"
            >
              <FontAwesomeIcon
                icon={faFigma}
                size="xl"
                className="hover:text-slate-900 text-slate-600"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/altmesh/"
              target="_blank"
              rel="noreferrer"
              className="lg:hover:scale-110 transition ease-in-out"
            >
              <FontAwesomeIcon
                icon={faLinkedinIn}
                size="2xl"
                className="hover:text-slate-900 text-slate-600"
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
